import * as React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Button from "../components/Button";
import LogoBlack from "../images/open-fulllogo-black.svg";
import Seo from "../components/Seo";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Not Found" />
      <div className="flex py-24">
        <img
          className="object-contain h-72 w-96 ml-16 -black "
          src={LogoBlack}
          alt="OPEN"
        />
        <div className=" text-left pl-16 pr-12 pt-12 ">
          <h1 className="font-extrabold pb-8 text-6xl ">
            404 - Page not found
          </h1>
          <p className="text-gray-500 text-xl">
            The page you’re looking for was not found.
          </p>
          <Link to="/">
            <Button classes="mt-8 px-4 ">Home</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
